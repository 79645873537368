<template lang="pug">
  section.section(
    :id="data.nav",
    :class="[mode, data.id]",
    v-scroll="{cb: scrollHandler, enable: isInView }",
    v-intersect
  )
    .background
      .cover(ref="cover", :style="{backgroundImage: `url('${data.background}')`}")
      .overlay(ref="overlay")
    .header
      .header-title
        h2.title-section(ref="title") {{ data.title }}
      p.summary(
        v-if="data.summary",
        ref="summary"
      ) {{ data.summary }}
    .content(
        :class="{rows: data.rows.length}",
        ref="content"
    )
      template(v-if="!!data.columns.length")
        .column(
          v-if="data.columns",
          v-for="(column, cIndex) in data.columns",
          :key="cIndex"
        )
          h3.title-column {{ column.subtitle }}
          p.summary(
            v-if="column.summary"
          ) {{ column.summary }}
          div.text(v-if="typeof column.text !== 'string'")
            p(v-for="(paragraph, pIndex) in column.text", :key="pIndex") {{ paragraph }}
          div.text(v-else, v-html="column.text")
          ul.text(v-if="column.list")
            li(v-for="(item, index) in column.list", :key="index") {{ item }}

      template(v-if="!!data.rows.length")
        .row(v-for="(row, rIndex) in data.rows", :key="rIndex")
          .row-header
            h3.title-row {{ row.subtitle }}
          div.text {{ row.text }}

</template>

<script>
import { TimelineLite } from "gsap";
import { round } from "../../utils/math";
import { fadeIn, fadeOut, parallax } from "../../utils/animation";
import scroll from "../../mixins/scroll";

export default {
  name: "Section",
  mixins: [scroll],
  props: {
    data: {
      type: Object,
      required: true
    },
    mode: String,
    required: true
  },
  data() {
    return {
      timeline: new TimelineLite({ paused: true })
    };
  },
  computed: {
    overlayOpacity() {
      switch (this.data.id) {
        case "adult":
          return 0.85;
        case "teenager":
          return 0.94;
        case "child":
          return 0.9;
        default:
          return 0.85;
      }
    }
  },
  mounted() {
    const { overlay, title, summary, content, cover } = this.$refs;

    const coverParallax = parallax({
      from: this.getOffset(-0.15),
      to: this.getOffset(0.15)
    });
    const titleParallax = parallax({
      from: this.getOffset(-0.2),
      to: this.getOffset(0.05)
    });
    const summaryParallax = parallax({
      from: this.getOffset(-0.18),
      to: this.getOffset(0.05)
    });
    const contentParallax = parallax({
      from: this.getOffset(-0.16),
      to: this.getOffset(0.05)
    });

    this.timeline
      .fromTo(cover, 2, ...coverParallax)
      .fromTo(overlay, 0.5, ...fadeIn(this.overlayOpacity), 0.6)
      .fromTo(overlay, 0.5, ...fadeOut(this.overlayOpacity), 1.4);

    if (title) {
      this.timeline
        .fromTo(title, 1, ...titleParallax, 0.2)
        .fromTo(title, 0.2, ...fadeIn(), 0.6)
        .fromTo(title, 0.2, ...fadeOut(), 1.2);
    }
    if (summary) {
      this.timeline
        .fromTo(summary, 1, ...summaryParallax, 0.22)
        .fromTo(summary, 0.2, ...fadeIn(), 0.65)
        .fromTo(summary, 0.2, ...fadeOut(), 1.25);
    }
    if (content) {
      this.timeline
        .fromTo(content, 1, ...contentParallax, 0.24)
        .fromTo(content, 0.2, ...fadeIn(), 0.7)
        .fromTo(content, 0.2, ...fadeOut(), 1.65);
    }

    this.hideElements();
  },
  methods: {
    doScroll() {
      const scrollOffset =
        window.scrollY - this.scrollOffset + this.viewportHeight;
      const scrollViewHeight = this.viewportHeight + this.height;
      const scrollProgress = round(scrollOffset / scrollViewHeight, 2);
      const scrollLimitedProgress = Math.min(scrollProgress, 1);
      this.timeline.progress(scrollLimitedProgress);
    }
  }
};
</script>

<style scoped lang="stylus" src="./Section.styl"></style>
