<template lang="pug">
  .open-map(ref="map")
</template>

<script>
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.imagePath = "";
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("../../assets/leaflet/marker-icon-2x.png"),
  iconUrl: require("../../assets/leaflet/marker-icon.png"),
  shadowUrl: require("../../assets/leaflet/marker-shadow.png"),
});

export default {
  name: "OpenMap",
  props: {
    name: { type: String, required: true },
  },
  data() {
    return {
      markerCoordinates: [43.5513766, 7.02313700000002],
    };
  },
  mounted() {
    const map = L.map(this.$refs.map, {
      center: this.markerCoordinates,
      zoom: 17,
      scrollWheelZoom: false,
    });
    L.tileLayer(
    "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
      {
        attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`,
      }
    ).addTo(map);
    L.marker(this.markerCoordinates).addTo(map);
  },
};
</script>

<style lang="stylus">
  @import "../../assets/styles/base"
  @import "leaflet"

  .open-map
    position relative
    z-index 0
    width 100%
    height 100vh
    margin 0 auto
    background $color-base
    overflow hidden

    &::before
      content: ''
      position absolute
      z-index 1
      top: 0
      right: 0
      bottom: 0
      left: 0
      background-color $color-light
      opacity .35
      pointer-events none
      transition opacity 150ms $motion

    &:hover
      &::before
        opacity: 0

</style>
