import { TweenMax, Sine } from "gsap";

const scroll = {
  data() {
    return {
      isInView: false,
      height: 0,
      scrollOffset: 0
    };
  },
  computed: {
    ints() {
      const int = this.viewportHeight / 2;
      const offset = this.viewportHeight / 2;
      return {
        in: [-offset, -offset + int],
        out: [this.height - offset, this.height - offset + int]
      };
    },
    readyToScroll() {
      return this.$el && Object.keys(this.$refs).length;
    },
    viewportHeight() {
      return window.innerHeight;
    }
  },
  mounted() {
    this.height = this.$el.offsetHeight;
    this.scrollOffset = this.$el.offsetTop;
  },
  methods: {
    scrollHandler() {
      if (this.readyToScroll && this.height) {
        this.doScroll();
      }
    },
    tween(el, time, options) {
      if (el) {
        TweenMax.to(el, time, {
          ...options,
          ease: Sine.easeOut
        });
      }
    },
    isExcludedFromHide(name) {
      return !["cover", "map", "swiper"].includes(name);
    },
    hideElement([name, ref]) {
      if (this.isExcludedFromHide(name)) {
        ref.style.opacity = 0;
      }
    },
    hideElements() {
      Object.entries(this.$refs).forEach(this.hideElement);
    },
    getOffset(ratio) {
      return this.viewportHeight * ratio;
    }
  }
};

export default scroll;
