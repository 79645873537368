import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home";
import NotFound from "./views/NotFound";
import Profile from "./views/Profile";
import Workshops from "./views/Workshops";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/ateliers",
    name: "workshops",
    component: Workshops
  },
  {
    path: "/profil",
    name: "profile",
    component: Profile
  },
  {
    path: "*",
    component: NotFound
  }
];

export default new Router({
  // mode: "history",
  routes,

  scrollBehavior(to, from) {
    const { hash } = to;
    if (hash) {
      const delay = from.name === "workshops" ? 1000 : 0;
      setTimeout(() => {
        return window.scrollTo({
          top: document.querySelector(hash).offsetTop,
          behavior: "smooth"
        });
      }, delay);
    }
    return window.scrollTo({ top: 0, behavior: "smooth" });
  }
});
