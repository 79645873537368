import { Power0, Power2 } from "gsap";

export const fadeIn = (alpha = 1) => [
  { alpha: 0 },
  { alpha: alpha, ease: Power2.easeOut }
];

export const fadeOut = (alpha = 1) => [
  { alpha: alpha },
  { alpha: 0, ease: Power2.easeIn }
];

export const parallax = ({ from, to }) => [
  { y: from },
  { y: to, ease: Power0.easeNone }
];
