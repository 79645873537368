<template lang="pug">
  .workshops
    Title(:data="title")
    Section(:data="workshops", mode="rows")
    Section(:data="details", mode="center")
    Copy
</template>

<script>
import Copy from "@/components/Copy.vue";
import SectionColumns from "@/components/SectionColumns";
import Section from "@/components/Section/Section";
import Title from "@/components/Title/Title";

export default {
  name: "workshops",
  components: {
    Copy,
    SectionColumns,
    Section,
    Title
  },
  data() {
    return this.$wpData.workshops;
  }
};
</script>
