<template lang="pug">
    section(
        :id="data.nav",
        :class="[data.id]",
        v-scroll="{cb: scrollHandler, enable: isInView }",
        v-intersect
    )
        .office__slider
            .swiper-container(ref="swiper")
                .swiper-wrapper
                    .swiper-slide(v-for="image in data.gallery", :key="image")
                        img(:src="image")
                .swiper-button-prev.swiper-button-white
                .swiper-button-next.swiper-button-white
        .office__content.section(ref="content")
            h2.title-section {{ data.title }}
            div.text
                p {{ data.summary }}

            template(v-for="session in data.sessions")

                h2.title-section {{ session.title }}
                small.office__mention {{ session.detail }}
                .office__infos
                    .office__hours
                        h3.title-column Horaires
                        p.text(v-if="session.hours.title") {{ session.hours.title }}
                        p.text(v-for="hour in session.hours.list")
                            strong {{ hour.label }}
                            span  {{ hour.value }}
                    .office__prices
                        h3.title-column Tarifs
                        p.text(v-for="price in session.prices")
                            span {{ price.label }}
                            strong {{ price.value }} €

</template>

<script>
import { TimelineLite } from "gsap";
import Swiper from "swiper";
import { round } from "../../utils/math";
import { fadeIn, fadeOut, parallax } from "../../utils/animation";
import scroll from "../../mixins/scroll";

export default {
  name: "Office",
  mixins: [scroll],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeline: new TimelineLite({ paused: true })
    };
  },
  mounted() {
    const { content, swiper } = this.$refs;

    setTimeout(() => {
      new Swiper(swiper, {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    }, 1000);

    const swiperParallax = parallax({
      from: this.getOffset(-0.15),
      to: this.getOffset(0.15)
    });
    const contentParallax = parallax({
      from: this.getOffset(-0.16),
      to: this.getOffset(0.05)
    });

    this.timeline
      .fromTo(swiper, 2, ...swiperParallax)
      .fromTo(content, 1, ...contentParallax, 0.24)
      .fromTo(content, 0.2, ...fadeIn(), 0.7)
      .fromTo(content, 0.2, ...fadeOut(), 1.65);

    this.hideElements();
  },
  methods: {
    doScroll() {
      const scrollOffset =
        window.scrollY - this.scrollOffset + this.viewportHeight;
      const scrollViewHeight = this.viewportHeight + this.height;
      const scrollProgress = round(scrollOffset / scrollViewHeight, 2);
      const scrollLimitedProgress = Math.min(scrollProgress, 1);
      this.timeline.progress(scrollLimitedProgress);
    }
  }
};
</script>

<style scoped lang="stylus" src="./Office.styl"></style>
