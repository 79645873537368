<template lang="pug">
  section#contact.contact(
    v-scroll="{cb: scrollHandler, enable: isInView }",
    v-intersect
  )
    .section.contact__content(ref="content")
      h2.title-section {{ data.title }}
      template(v-for="(address, index) in data.addresses")
        h3.title-column Cabinet de {{ address.location }}
        div.text
          p {{ address.address }}
          p – {{ address.phone }}

      div(v-html="form")

    .contact__map(ref="map")
      open-map(name="contact")

</template>

<script>
import OpenMap from "../OpenMap/OpenMap.vue";
import { TimelineLite } from "gsap";
import { round } from "../../utils/math";
import { fadeIn, fadeOut, parallax } from "../../utils/animation";
import scroll from "../../mixins/scroll";

export default {
  name: "Contact",
  mixins: [scroll],
  components: { OpenMap },
  props: {
    data: {
      type: Object,
      required: true
    },
    form: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      timeline: new TimelineLite({ paused: true })
    };
  },
  mounted() {
    const { content, map } = this.$refs;

    const mapParallax = parallax({
      from: this.getOffset(-0.1),
      to: this.getOffset(0.15)
    });
    const contentParallax = parallax({
      from: this.getOffset(-0.16),
      to: this.getOffset(0.05)
    });

    this.timeline
      .fromTo(map, 2, ...mapParallax)
      .fromTo(content, 1, ...contentParallax, 0.24)
      .fromTo(content, 0.2, ...fadeIn(), 0.7)
      .fromTo(content, 0.2, ...fadeOut(), 1.65);

    this.hideElements();
  },
  methods: {
    doScroll() {
      const scrollOffset =
        window.scrollY - this.scrollOffset + this.viewportHeight;
      const scrollViewHeight = this.viewportHeight + this.height;
      const scrollProgress = round(scrollOffset / scrollViewHeight, 2);
      const scrollLimitedProgress = Math.min(scrollProgress, 1);
      this.timeline.progress(scrollLimitedProgress);
    }
  }
};
</script>

<style scoped lang="stylus" src="./Contact.styl"></style>
