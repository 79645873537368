<template lang="pug">
  transition
    .title(
      v-scroll="{cb: scrollHandler, enable: isInView }",
      v-intersect
    )
      .title__background
        .title__overlay(ref="overlay")
      .title__content
          h1(v-html="data", ref="title")
</template>

<script>
import { TimelineLite, Power2 } from "gsap";
import { round } from "../../utils/math";
import { fadeOut } from "../../utils/animation";
import scroll from "../../mixins/scroll";

export default {
  name: "Title",
  mixins: [scroll],
  props: {
    data: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      timeline: new TimelineLite({ paused: true }),
      overlayOpacity: 0.83
    };
  },
  mounted() {
    const { overlay, title } = this.$refs;
    const titleStates = [{ y: 0 }, { y: "105%", ease: Power2.easeIn }];
    this.timeline
      .to(this.$el, 2, { alpha: 1 })
      .fromTo(overlay, 1.25, ...fadeOut(this.overlayOpacity), 0)
      .fromTo(title, 1.25, ...fadeOut(), 0)
      .fromTo(title, 1.25, ...titleStates, 0);
  },
  methods: {
    doScroll() {
      const scrollOffset = window.scrollY;
      const scrollProgress = round(scrollOffset / this.height, 2);
      const scrollLimitedProgress = Math.min(scrollProgress, 1);
      this.timeline.progress(scrollLimitedProgress);
    }
  }
};
</script>

<style scoped lang="stylus" src="./Title.styl"></style>
