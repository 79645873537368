<template lang="pug">
  section#detail.section.section-columns
    .background
    h2.title-section {{ data.title }}
    .content
      .column(v-for="(column, index) in data.columns")
        h3.title-column {{ column.subtitle }}
        p.text {{ column.text }}
        ul.text
          li(v-for="(item, index) in column.list", :key="index") {{ item }}

</template>

<script>
export default {
  name: "SectionColumns",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="stylus">
  @import "../assets/styles/base"

  $color-teenager = rgb(185, 193, 111)
  $color-teenager-text = rgb(62, 66, 60)

  .section-columns
    text-align center

    .title-section
      color $color-light

    .title-column
      color $color-teenager-text

    .background
      &::before
        background-color $color-teenager

    .content
      margin-top rem(90)
      color $color-teenager-text
      text-align left

    ul
      padding-left 2em
      list-style-type none

    li
      position relative
      margin-bottom rem(10)
      &::before
        position absolute
        content '—'
        left -2em

</style>
