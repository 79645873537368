<template lang="pug">
  .home
    Hero(:data="hero")
    Section(:data="adult", mode="split")
    Section(:data="teenager", mode="center")
    Section(:data="child", mode="rows")
    Office(:data="office")
    Contact(:data="contact", :form="contactForm")
    Copy
</template>

<script>
import Contact from "@/components/Contact/Contact.vue";
import Copy from "@/components/Copy.vue";
import Hero from "@/components/Hero/Hero.vue";
import Office from "@/components/Office/Office";
import Section from "@/components/Section/Section";

export default {
  name: "home",
  components: {
    Contact,
    Copy,
    Hero,
    Office,
    Section
  },
  data() {
    return this.$wpData.home;
  }
};
</script>
