<template lang="pug">
  .profile
    .paper(:style="{backgroundImage: `url('${background}')`}")
      router-link.close(to="/")
        include ../assets/icons/close.svg
      .scroll
        h3.title-section {{ title }}
        div.text {{ text }}
</template>

<script>
export default {
  name: "Profile",
  data() {
    return this.$wpData.profile;
  }
};
</script>

<style scoped lang="stylus">
  @import "../assets/styles/base"

  .profile
    perspective 900px
    display flex
    position absolute
    height 100%
    width 100%
    z-index $depth-profile
    background-color alpha($color-base, 0.9)
    padding rem(10)
    box-sizing border-box
    overflow hidden

  .paper
    position relative
    flex-grow 1
    padding rem(30)
    background white no-repeat center center / cover
    +mobile()
      background-image none !important
    +desktop()
      padding rem(110)
      display flex

  .scroll
    overflow-x hidden
    overflow-y auto
    +mobile()
      height 100%
      padding-right 1em
    +desktop()
      flex-basis 50%

  .title-section
    margin-left 0
    color $color-base

  .text
    max-width 32em
      white-space pre-line

  .close
    position absolute
    top rem(20)
    right rem(20)
    color $color-base

    svg
      stroke $color-base
      height rem(32)
      width rem(32)
      stroke-dashoffset -4
      stroke-dasharray 20 30
      transition stroke 250ms $motion, stroke-dasharray 300ms $motion, stroke-dashoffset 300ms $motion, transform 150ms $motion

    &:hover
      svg
        stroke $color-text
        stroke-dashoffset 0
        stroke-dasharray 30 30
        transform rotate(90deg)

  .profile-transition-enter-active
    .paper
      transition-duration 750ms

  .profile-transition-enter
    .paper
      transform: translate3d(0,-5%,0) rotate3d(1,0,0,5deg)

  .profile-transition-leave-to
    .paper
      transform: translate3d(0,5%,0) rotate3d(1,0,0,5deg)

  .profile-transition-enter-to,
  .profile-transition-leave
    .paper
      transform translate3d(0,0,0) rotate3d(0,0,0,0deg)

  .profile-transition-leave-active
    .paper
      transition-duration 500ms

</style>
