<template lang="pug">
  footer.copy
    router-link.copy__logo(to="/")
      include ../assets/logo.svg
    p.copy__text
      | ©2018 Charlène Maheo
      | Psychologue, Cannes
</template>

<script>
export default {
  name: "Copy",
  data() {
    return {
      links: {
        adulte: "L’adulte",
        adolescent: "L’adolescent",
        enfant: "L’enfant",
        cabinet: "Le cabinet",
        contact: "Contact"
      }
    };
  }
};
</script>

<style scoped lang="stylus">
  @import "../assets/styles/base"

  .copy
    margin-top -7vh
    padding: rem(50) rem(30) rem(30)
    text-align center
    background-color white
    clip-path polygon(0 $diagonal-mobile, 100% 0, 100% 100%, 0 100%)
    +desktop()
      padding: rem(70) rem(50) rem(50)
      clip-path polygon(0 $diagonal, 100% 0, 100% 100%, 0 100%)

    &__logo
      height rem(36)
      width rem(36)
      +desktop()
        height rem(54)
        width rem(54)

      svg
          height inherit
          width inherit
          fill $color-faded
          transition fill 150ms cubic-bezier(0.4, 0.0, 0.2, 1);

      &:hover
          svg
              fill darken($color-faded, 25%)
    &__text
      margin rem(10) auto
      color $color-faded-darken
      copy()
      +desktop()
        max-width 22em

</style>
