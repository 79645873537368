<template lang="pug">
    #app
        Navigation
        transition(:name="routerTransition", mode="out-in")
            router-view
</template>

<script>
import Navigation from "@/components/Navigation/Navigation";

export default {
  name: "app",
  components: {
    Navigation
  },
  data() {
    return {
      routerTransition: "page-transition"
    };
  },
  watch: {
    $route(to) {
      this.routerTransition =
        to.name === "profile" ? "profile-transition" : "page-transition";
    }
  }
};
</script>

<style scoped lang="stylus">
    @import "assets/styles"

    #app
        font-family 'Raleway', Helvetica, Arial, sans-serif

    .page-transition-enter-active,
    .profile-transition-enter-active
        transition-duration 1s

    .page-transition-enter,
    .profile-transition-enter,
    .page-transition-leave-to,
    .profile-transition-leave-to
        opacity 0

    .page-transition-enter-to,
    .profile-transition-enter-to,
    .page-transition-leave,
    .profile-transition-leave
        opacity 1

    .page-transition-leave-active,
    .profile-transition-leave-active
        transition-duration 750ms

</style>
