<template lang="pug">
  transition(
      appear="",
      appear-class="nav-appear",
      appear-to-class="nav-appear-to",
      appear-active-class="nav-appear-active"
  )
      nav.nav(:class="{fixed: isFixed}")
        router-link.nav__logo(to="/")
          include ../../assets/logo.svg
        ul.nav__links
          li.nav__link(v-for="(label, id) in links", :key="id")
              router-link(:to="`/#${id}`") {{ label }}
          li.nav__button
            router-link(to="/ateliers") Les ateliers
        router-link.nav__profile(to="/profil")
          img(src="../../assets/profile/avatar.jpg")

</template>

<script>
export default {
  name: "navigation",
  beforeMount() {
    window.addEventListener("scroll", this.scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  data() {
    return {
      links: {
        adulte: "L’adulte",
        adolescent: "L’adolescent",
        enfant: "L’enfant",
        cabinet: "Le cabinet",
        contact: "Contact"
      },
      isFixed: false
    };
  },
  methods: {
    scrollHandler() {
      const supportPageOffset = window.pageYOffset !== undefined;
      const isCSS1Compat = document.compatMode || "" === "CSS1Compat";
      const fromTop = supportPageOffset
        ? window.pageYOffset
        : isCSS1Compat
          ? document.documentElement.scrollTop
          : document.body.scrollTop;
      const nav = this.$el;
      this.isFixed = nav && fromTop > nav.offsetHeight;
    }
  }
};
</script>

<style scoped lang="stylus" src="./Navigation.styl"></style>
