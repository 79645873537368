let io = null;
export const intersect = {
  bind(el, binding, vnode) {
    io = new IntersectionObserver(entries => {
      vnode.context.isInView = entries[0].isIntersecting;
    });
    io.observe(el);
  },
  unbind(el) {
    io.unobserve(el);
    io.disconnect();
  }
};
