import Vue from "vue";
import { intersect } from "./directives/intersect";
import { scroll } from "./directives/scroll";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;
Vue.directive("intersect", intersect);
Vue.directive("scroll", scroll);

Vue.prototype.$wpData = window.cm_data;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
