export const scroll = {
  bind(el, binding) {
    window.addEventListener("scroll", binding.value.cb);
  },
  update(el, binding) {
    const action = binding.value.enable
      ? "addEventListener"
      : "removeEventListener";
    window[action]("scroll", binding.value.cb);
  },
  unbind(el, binding) {
    window.removeEventListener("scroll", binding.value.cb);
  }
};
